document.addEventListener('DOMContentLoaded', function () {
    const screenWidth = window.innerWidth;
    const description = document.querySelector('#home__description');
    const offer = document.querySelector('.home__offer');
    const video = document.querySelector('.home__video');
    const wrapper = document.querySelector('.home__wrapper');
    const body = document.querySelector('body');
    const is_pc = screenWidth > 992;
    const fixed_value_margin = is_pc ? 110 : 160;

    let is_init_app = false;

    function setInitialPosition() {
        const offerHeight = offer.offsetHeight;
        const descriptionHeight = description.offsetHeight;
        const totalHeight = offerHeight + descriptionHeight;
        if (is_pc) {
            wrapper.style.height = `${totalHeight}px`;
            description.style.bottom = `-${descriptionHeight}px`;
        }
        offer.style.top = '0px';
    }

    let scrollTimeout = null; // Таймер для отслеживания окончания скролла

    function handleScrollPC() {
        const scrollPosition = window.scrollY;
        const descriptionHeight = description.offsetHeight;

        // Оригинальный код трансформации
        let startScroll = 0;

        if (scrollPosition > startScroll) {
            const translateValue = Math.min(scrollPosition, descriptionHeight);
            if (translateValue >= 110) {
                description.style.transform = `translateY(-${translateValue}px)`;
            }
        } else {
            description.style.transform = 'translateY(-110px)';
        }

        startScroll = fixed_value_margin;

        if (scrollPosition > startScroll) {
            const progress = Math.min((scrollPosition - startScroll) / descriptionHeight, 1);
            const index = 0.03;
            const scaleValue = 1 - (progress * index);
            // offer.style.transform = `translateX(-50%) scale(${scaleValue})`;
        } else {
            // offer.style.transform = 'translateX(-50%) scale(1)';
        }

        // Очищаем предыдущий таймер, если он есть
        if (scrollTimeout) {
            clearTimeout(scrollTimeout);
        }

        // Устанавливаем новый таймер, чтобы определить, когда скролл закончен
        scrollTimeout = setTimeout(() => {
            const videoDuration = video.duration;
            if (videoDuration) {
                const scrollProgress = Math.min(scrollPosition / descriptionHeight, 1);
                // Обновляем видео только после завершения скролла
                video.currentTime = scrollProgress * videoDuration;
            }
        }, 100); // Ожидаем 100 мс после остановки скролла
    }


    function handleScrollMobile() {
        const scrollPosition = wrapper.scrollTop;
        const descriptionHeight = description.offsetHeight;

        // Управляем видео
        const videoDuration = video.duration;
        if (videoDuration) {
            const scrollProgress = Math.min(scrollPosition / descriptionHeight, 1);
            video.currentTime = scrollProgress * videoDuration;
        }

        let startScroll = 0;

        if (scrollPosition < fixed_value_margin) {
            if (is_init_app) {
                wrapper.scrollTo({ top: fixed_value_margin, behavior: 'auto' });
            }
            return;
        }

        startScroll = fixed_value_margin;
        if (scrollPosition > startScroll) {
            const progress = Math.min((scrollPosition - startScroll) / descriptionHeight, 1);
            const index = 0.08;
            const scaleValue = 1 - (progress * index);
            offer.style.transform = `translate(0, 0) scale(${scaleValue})`;
        }
    }

    window.addEventListener('load', function () {
        setInitialPosition();

        setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'auto' });
        }, 0);

        setTimeout(() => {
            video.style.opacity = 1;
            video.style.transform = `scale(1)`;
        }, 200);

        setTimeout(() => {
            description.style.top = '1';
            description.style.opacity = '1';
            description.style.visibility = 'visible';
            body.classList.remove('hidden');
            body.classList.add('overflow');
            if (is_pc) {
                description.style.transform = 'translateY(-110px)';
            } else {
                wrapper.scrollTo({ top: fixed_value_margin, behavior: 'smooth' });
                setTimeout(() => {
                    is_init_app = true;
                    video.style.transition = 'all 0.1s ease-out';
                }, 300);
            }
        }, 1300);
    });

    window.addEventListener('resize', setInitialPosition);
    is_pc
        ? window.addEventListener('scroll', handleScrollPC)
        : wrapper.addEventListener('scroll', handleScrollMobile);
});
